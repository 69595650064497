import React, { useState, useEffect, useContext } from 'react'
import Bottleneck from 'bottleneck/es5'
import { request } from 'graphql-request'
import Banner from '../Banner'
import { Grid, GridCell } from '@rmwc/grid'
import ErrorMessage from '~/components/ErrorMessage'
import Button from '~/components/Button'
import moment from 'moment'
import Select from '~/components/Select'
import useShowLoader from '../Loader'
import { formatDurationForDisplay, dateRange, timeRange, fullDate, compareDatesAndTimes } from '~/helpers/dateAndTime'
import stripTags from '~/helpers/stripTags'
import { connect } from 'react-redux'
import http, { preparePayload, formatErrors, wrapButtonToggle } from '~/http'
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog'
import recaptcha from '~/helpers/loadRecaptcha'
import TextField from '../TextField'
import { HeaderContext } from '../../App'

import './CourseDetailPage.scss'

const courseQuery = `
query ($courseId: Int!) {
  wedOeCourses(courseId: $courseId) {
    title
    description
    id
    sections {
      id
      availableCapacity
      location
      maxCapacity
      courseSectionNumber
      startDate
      endDate
      startTime
      endTime
      meetingPattern
      notEnrollableReason
      price
      priceDisplay
      tuitionDisplay
      suppliesDisplay
      bookDisplay
      testDisplay
      prerequisite
      scheduleNotes
      isCeEligible
      ceEligibility
    }
  }
}
`

const limiter = new Bottleneck({
  maxConcurrent: 1,
  minTime: 800,
  highWater: 1
})

function CourseDetailPage (props) {
  const { cart, match, students, history } = props
  const [globalError, setGlobalError] = useState(null)
  const [course, setCourse] = useState(null)
  const [descriptionDetails, setDescriptionDetails] = useState(false)

  const [addButtonDisabled, setAddButtonDisabled] = useState(false)

  const captureId = match.params.id
  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'Class Detail Page' })

    ignoreRateLimiting(async () => {
      try {
        const { wedOeCourses } = await request('/api/public/graphql', courseQuery, { courseId: captureId })
        if (wedOeCourses.length === 1) {
          setCourse(wedOeCourses[0])
          setDescriptionDetails(calculateDescription(wedOeCourses[0].description))
        }
      } catch (err) {
        // FIXME: eat error for now
      }
    })
  }, [])

  const sections = course ? course.sections : []
  const locationOptions = [...new Set(sections.map(s => s.location))]

  const showLoader = useShowLoader()

  const [dateOptions, setDateOptions] = useState([...new Set(sections.map(s => dateRange(s.startDate, s.endDate)))])
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(false)

  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedSectionId, setSelectedSectionId] = useState(null)
  const [selectedSection, setSelectedSection] = useState(null)

  const cartContentsBySection = cart.reservationsByType.WEDOE.reduce((acc, r) => {
    acc[`${r.section.id} ${r.studentPublicId}`] = r
    return acc
  }, {})

  function updateLocation (location) {
    if (selectedSection) {
      setSelectedSection(null)
    }

    const validDates = sections
      .filter(s => s.location === location)
      .sort((a, b) => compareDatesAndTimes(a, b))
      .map(s => { return { label: `${fullDate(s.startDate)}${s.startTime ? ' - ' + formatDurationForDisplay(s.startTime) : ''}`, value: s.id } })

    if (selectedSectionId && !validDates.find(x => x.value === selectedSectionId)) {
      setSelectedSectionId(null)
    }
    setDateOptions(validDates)

    setSelectedLocation(location)
  }

  useEffect(() => {
    if (selectedSectionId && selectedLocation) {
      const matchingSection = sections.find(s => s.location === selectedLocation && s.id.toString() === selectedSectionId)
      if (matchingSection) {
        setSelectedSection(matchingSection)
      }
    }
  }, [selectedLocation, selectedSectionId])

  const capacityDisplay = selectedSection
    ? `${selectedSection.availableCapacity} seat${selectedSection.availableCapacity > 1 ? 's' : ''} open`
    : '0 seats open'
  // const capacityClassName = selectedSection
  //   ? selectedSection.availableCapacity <= 0 ? 'full' : selectedSection.availableCapacity <= 3 ? 'near-full' : 'not-full'
  //   : ''
  const notEnrollableReason = selectedSection
    ? selectedSection.notEnrollableReason
    : null
  const enrollable = notEnrollableReason == null
  const isFull = selectedSection
    ? selectedSection.availableCapacity <= 0
    : false

  return (
    course
      ? (
        <div className='course-detail-page'>
          <Banner message={course.title} />

          <div className='course-detail-page-sections'>
            {props.history.length > 1 &&
              <Button
                className='backButton'
                label='Back to Previous Page'
                onClick={() => {
                  window.scrollTo(0, 0)
                  props.history.goBack()
                }}
              />}
            <Grid>
              <GridCell desktop={8} tablet={12}>
                <h3>{course.title}</h3>
                <p className='description'>
                  {!descriptionDetails.isExpanded &&
                    <>
                      {descriptionDetails.short}
                      &nbsp;
                      {descriptionDetails.needsExpand &&
                        <button
                          className='description-toggle-btn'
                          onClick={() => {
                            setDescriptionDetails({ ...descriptionDetails, isExpanded: true })
                          }}
                        >Show more...
                        </button>}
                    </>}

                  {descriptionDetails.isExpanded &&
                    <>
                      {descriptionDetails.full}
                      &nbsp;
                      {descriptionDetails.needsExpand &&
                        <button
                          className='description-toggle-btn'
                          onClick={() => {
                            setDescriptionDetails({ ...descriptionDetails, isExpanded: false })
                          }}
                        >Show less
                        </button>}
                    </>}
                </p>

                <div>
                  {selectedSection && selectedSection.scheduleNotes &&
                    <div>
                      <h5>Schedule Notes</h5>
                      <p>{selectedSection.scheduleNotes}</p>
                    </div>}

                  {
                    selectedSection && selectedSection.isCeEligible && (
                      <div className='item row selected-section'>
                      This class is eligible for continuing education credit.
                      </div>
                    )
                  }
                </div>
                {
                  globalError && <ErrorMessage message={globalError} />
                }
              </GridCell>

              <GridCell desktop={4} tablet={12} className='section-select-form'>
                <div>
                  <h4>Select Section</h4>

                  <Select
                    name='Location'
                    required
                    value={selectedLocation}
                    options={locationOptions}
                    setter={updateLocation}
                  />

                  <Select
                    disabled={selectedLocation === ''}
                    name='Start Date'
                    required
                    options={dateOptions}
                    setter={setSelectedSectionId}
                  />
                </div>

              </GridCell>

              {selectedSection &&
                <>
                  <GridCell desktop={4} tablet={12} className='section-details-table'>
                    <table>
                      <tbody>
                        <tr>
                          <th colSpan='2'>
                            <h4>Details</h4>
                          </th>
                        </tr>
                        <tr>
                          <th>Location</th>
                          <td>{selectedSection.location}</td>
                        </tr>
                        <tr>
                          <th>Class Section Number</th>
                          <td>{selectedSection.courseSectionNumber}</td>
                        </tr>
                        <tr>
                          <th>Dates</th>
                          <td>{dateRange(selectedSection.startDate, selectedSection.endDate)}</td>
                        </tr>
                        <tr>
                          <th>Open Seats</th>
                          <td>{capacityDisplay}</td>
                        </tr>
                        {selectedSection.meetingPattern &&
                          <tr>
                            <th>Schedule</th>
                            <td>{selectedSection.meetingPattern} {selectedSection.startTime != null && timeRange(selectedSection.startTime, selectedSection.endTime)}</td>
                          </tr>}
                      </tbody>
                    </table>
                  </GridCell>
                  <GridCell desktop={4} tablet={12} className='section-details-table'>
                    <table>
                      <tbody>
                        <tr>
                          <th colSpan='2'>
                            <h4>Costs</h4>
                          </th>
                        </tr>
                        <tr>
                          <th>Tuition</th>
                          <td>{selectedSection.tuitionDisplay}</td>
                        </tr>
                        <tr>
                          <th>Supplies</th>
                          <td>{selectedSection.suppliesDisplay}</td>
                        </tr>
                        <tr>
                          <th>Books</th>
                          <td>{selectedSection.bookDisplay}</td>
                        </tr>
                        <tr>
                          <th>Tests</th>
                          <td>{selectedSection.testDisplay}</td>
                        </tr>
                        <tr>
                          <th>Total Cost</th>
                          <td>{selectedSection.priceDisplay}</td>
                        </tr>
                      </tbody>
                    </table>
                  </GridCell>

                </>}

              {selectedSection &&
                <GridCell desktop={4} tablet={12}>
                  {
                    !enrollable && (
                      <div className='not-enrollable-alert'>
                        <b>{notEnrollableReason}</b>
                        <div>
                            Please give us a call at 405-717-4900 between the hours of 8:00 AM and 4:30 PM and we'll assist you.
                        </div>
                      </div>
                    )
                  }

                  {enrollable && students.length === 0 && (
                    <div className='no-student-found'>
                      <p>No students found. Please add a student to enroll in a class.</p>

                      <Button
                        label='Add Student'
                        icon='person_add'
                        unelevated
                        onClick={() => {
                          history.push('/addstudent')
                          window.scrollTo(0, 0)
                        }}
                      />
                    </div>
                  )}

                  {enrollable && students.length > 0 &&
                    <div className='section-details-table'>
                      <table>
                        <tr>
                          <th>
                            <h4>
                              Enroll Students
                            </h4>
                          </th>
                        </tr>
                        {students.map((student, idx) => {
                          const reservation = cartContentsBySection[`${selectedSection.id} ${student.publicId}`]
                          const ageOnStartDate = moment(selectedSection.startDate).diff(student.birthDate, 'years')
                          const ageError = ageOnStartDate < 16 ? 'Too young' : null
                          const getAction = function () {
                            if (reservation != null) {
                              // Remove from cart
                              return (
                                <Button
                                  icon='delete'
                                  label='Remove'
                                  outlined
                                  dense
                                  disabled={removeButtonDisabled}
                                  onClick={
                                    wrapButtonToggle(
                                      setRemoveButtonDisabled,
                                      async () => {
                                        showLoader(true)
                                        await removeFromCart(props, setGlobalError, reservation, student)
                                        showLoader(false)
                                      })
                                  }
                                />
                              )
                            } else if (isFull) {
                              return <span>Class is Full</span>
                            } else if (ageError != null) {
                              return <span>{ageError}</span>
                            } else {
                              // Add to cart
                              return (
                                <>
                                  {
                                    !selectedSection.isCeEligible
                                      ? (
                                        <Button
                                          label='Add to Cart'
                                          unelevated
                                          dense
                                          disabled={addButtonDisabled}
                                          onClick={
                                            wrapButtonToggle(
                                              setAddButtonDisabled,
                                              async () => {
                                                showLoader(true)
                                                await addToCart(props, setGlobalError, student, selectedSection, course)
                                                showLoader(false)
                                              })
                                          }
                                        />
                                      )
                                      : (
                                        <CeLicenseDialogButton {...{ ceEligibility: selectedSection.ceEligibility, props, student, selectedSection, setGlobalError, showLoader, course }} />
                                      )
                                  }
                                </>
                              )
                            }
                          }
                          return (
                            <tr key={idx}>
                              <th>
                                {`${student.firstName} ${student.lastName}`}
                              </th>
                              <td>
                                {getAction()}
                              </td>
                            </tr>
                          )
                        })}
                      </table>
                    </div>}
                </GridCell>}
            </Grid>
          </div>
        </div>)
      : null
  )
}

function CeLicenseDialogButton (props) {
  const { ceEligibility, parentProps, student, selectedSection, setGlobalError, showLoader, course } = props

  const defaultForm = {}
  const [open, setOpen] = useState(false)
  const [formValues, setFormValues] = useState(defaultForm)
  const [fieldErrors, setFieldErrors] = useState([])
  const [localError, setLocalError] = useState(undefined)

  const isCleet = ceEligibility.includes('CLEET')
  const isInsurance = ceEligibility.filter(type => type.indexOf('Insurance') !== -1).length > 0

  async function captureCeLicense () {
    let token = null
    try {
      token = await recaptcha('captureCeLicense')
    } catch {
      // silently fail on recaptcha error
      return
    }

    const ceLicenses = ceEligibility.reduce((acc, type) => {
      if (formValues[type] != null && formValues[type] !== '') {
        acc.push({ type, number: formValues[type] })
      }
      return acc
    }, [])

    const tempPayload = {
      recaptchaToken: token,
      ceLicenses
    }

    if (formValues.SSN != null && formValues !== '') {
      tempPayload.ssn = formValues.SSN
    }

    if (formValues.npn != null && formValues !== '') {
      tempPayload.npn = formValues.npn
    }

    const payload = preparePayload(tempPayload)

    try {
      showLoader(true)
      await http(setGlobalError, http => http.post(`/api/SelfService/Students/${student.publicId}/AddCeInfo`, payload))
      await addToCart(parentProps, setGlobalError, student, selectedSection, course)
    } catch (err) {
      if (err.response != null && err.response.status === 400) {
        setLocalError('Please fix the errors on the page and try again.')
        setFieldErrors(formatErrors(err.response.data))
      } else if (err.response != null && err.response.status === 422) {
        setLocalError(err.response.data.message)
        setFieldErrors({})
      } else if (err.response != null && err.response.status === 424) {
        setLocalError('It looks like you\'re going pretty fast! Please wait a moment and try again, or contact us at 405-717-4900 to enroll.')
        setFieldErrors({})
      } else {
        setLocalError('Unexpected error: Please refresh the page and try again or call us at 405-717-4900.')
        setFieldErrors({})
      }
    } finally {
      showLoader(false)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={evt => {
          setOpen(false)
          setFormValues(defaultForm)
        }}
        className='full-page'
      >
        <DialogTitle>Continuing Education Credit</DialogTitle>
        {
          localError !== undefined && <ErrorMessage message={localError} />
        }
        <DialogContent>
          <p>
            The class you’ve selected for enrollment is eligible for continuing education credit for {ceLicenseTypeDisplay(ceEligibility)}.
            A license is not required to take the class.
            However, if you are taking the class to meet CE requirements, please provide your license type, license number and/or Social Security number, as required by the governing agency.
            Failure to provide your information could result in a delay in, or not receiving credit for the class.
            If you have further questions, please call 405-717-4900.
          </p>
          <form name='ce-license' action='POST' method='/' onSubmit={(e) => e.prevenDefault()}>
            {
              ceEligibility.map((type, idx) => {
                return (
                  <CeLicenseNumberField
                    {...{ type, formValues, setFormValues, fieldErrors }}
                    key={idx}
                  />
                )
              })
            }
            {
              isCleet && (
                <>
                  <CeLicenseNumberField
                    {...{ formValues, setFormValues, fieldErrors }}
                    type='SSN'
                  />
                  <span>SSN is required for CLEET CE credit</span>
                </>
              )
            }
            {
              isInsurance && (
                <>
                  <CeLicenseNumberField
                    {...{ formValues, setFormValues, fieldErrors }}
                    type='npn'
                  />
                  <span>NPN is required for Insurance CE credit</span>
                </>
              )
            }
          </form>
        </DialogContent>
        <DialogActions>
          <DialogButton action='close'>Cancel</DialogButton>
          <DialogButton
            action='accept'
            onClick={async () => {
              showLoader(true)
              await addToCart(parentProps, setGlobalError, student, selectedSection, course)
              showLoader(false)
            }}
            isDefaultAction
          >
            Not taking continuing education
          </DialogButton>
          <DialogButton onClick={() => captureCeLicense()}>
            Add my license and enroll
          </DialogButton>
        </DialogActions>
      </Dialog>
      <Button
        label='Add to Cart'
        unelevated
        dense
        onClick={() => setOpen(true)}
      />
    </>
  )
}

function CeLicenseNumberField ({ type, formValues, setFormValues, fieldErrors }) {
  const label = !['SSN', 'npn'].includes(type)
    ? `${propDisplayString(type)} License Number`
    : type === 'SSN'
      ? 'SSN (no hyphens)'
      : type.toUpperCase()
  return (
    <TextField
      className='full-width'
      label={label}
      value={formValues[type]}
      setter={(e) => {
        const values = { ...formValues }
        values[type] = e
        setFormValues({ ...values })
      }}
    />
  )
}

async function ignoreRateLimiting (fn) {
  try {
    await limiter.schedule(() => fn())
  } catch (err) {
    if (!(err instanceof Bottleneck.BottleneckError)) {
      // Ignore the intentional throttling rejections
      throw err
    }
  }
}

function ceLicenseTypeDisplay (ceEligibility) {
  return propDisplayString(ceEligibility
    .reduce((acc, type, idx) => {
      if (idx === ceEligibility.length - 1) {
        acc += type
      } else if (idx === ceEligibility.length - 2) {
        acc += type + ' and '
      } else {
        acc += type + ', '
      }
      return acc
    }, ''))
}

function propDisplayString (propName) {
  return propName.replace(/([a-z0-9])([A-Z])/g, '$1 $2') // TODO: de-dup this
}

async function removeFromCart (props, setGlobalError, reservation, student) {
  const payload = {
    cartPublicId: props.cart.publicId,
    reservationPublicId: reservation.publicId,
    studentPublicId: student.publicId
  }

  const response = await http(setGlobalError, http => http.post('/api/SelfService/Cart/RemoveEnrollment', payload))
  props.dispatch({
    type: 'REMOVE_RESERVATION',
    data: {
      cart: response.data.cart,
      reservations: response.data.reservations
    }
  })
}

async function addToCart (props, setGlobalError, student, section, course) {
  let token = null
  try {
    token = await recaptcha('addToCart')
  } catch {
    // silently fail on recaptcha error
    return
  }

  const payload = {
    recaptchaToken: token,
    cartPublicId: props.cart.publicId,
    studentPublicId: student.publicId,
    sectionId: section.id
  }

  try {
    const response = await http(setGlobalError, http => http.post('/api/self-service/cart/wed/oe/add', payload))

    const startTime = section.startTime != null
      ? `${formatDurationForDisplay(section.startTime)}-${formatDurationForDisplay(section.endTime)}`
      : 'Online'

    props.dispatch({
      type: 'ADD_RESERVATION',
      data: {
        cart: response.data.cart,
        reservations: response.data.reservations,
        newReservationData: {
          courseId: course.id,
          courseName: course.title,
          section: {
            id: section.id,
            ageGroup: 'Adult',
            availableCapacity: section.availableCapacity,
            campus: section.location,
            courseSectionNumber: section.courseSectionNumber,
            price: section.price,
            notEnrollableReason: section.notEnrollableReason,
            startDate: `${fullDate(section.startDate)}-${fullDate(section.endDate)}`,
            startTime,
            prerequisite: section.prerequisite,
            isCeEligible: section.isCeEligible
          },
          studentName: `${student.firstName} ${student.lastName}`,
          studentPublicId: student.publicId,
          type: 'WEDOE'
        }
      }
    })
  } catch (err) {
    if (err.response != null && err.response.status === 400) {
      setGlobalError(err.response.data.message)
    } else if (err.response != null && err.response.status === 409) {
      setGlobalError('This student already has a reservation in this class.')
    } else if (err.response != null && err.response.status === 424) {
      setGlobalError('It looks like you\'re going pretty fast! Please wait a moment and try again, or contact us at 405-717-4900 to enroll into.')
    }
  }
}

function calculateDescription (description, minimumCharLength = 500) {
  description = description.trim()

  if (description[0] === '"') description = description.slice(1)
  if (description[description.length - 1] === '"') description = description.slice(0, description.length - 1)

  const words = stripTags(description).split(' ').filter(w => w.length > 0)
  const alwaysVisible = words.reduce((acc, word, i) => {
    const lastWord = acc.words[acc.words.length - 1]
    if (acc.length > minimumCharLength && lastWord[lastWord.length - 1] === '.') {
      return acc
    }
    acc.words.push(word)
    acc.length += word.length
    return acc
  }, { words: [], length: 0 })

  return {
    short: alwaysVisible.words.join(' '),
    full: words.join(' '),
    needsExpand: alwaysVisible.words.length < words.length,
    isExpanded: false
  }
}

export default connect(state => ({
  students: state.students.students,
  cart: state.cart
}))(CourseDetailPage)
